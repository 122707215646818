import React from "react";
import { useNavigate } from "react-router-dom";
import { Input, Button } from "antd";
import { registerValidator } from "../util/formValidations";
import { useForm } from "../../hooks";
import classnames from "classnames";
import Logo from "./Logo";
import Sidebar from "../UI/Sidebar";
// import { MdDashboardCustomize } from "react-icons/md";
import { useDispatch } from "react-redux";
import { registerClient } from "../../redux/users/userActions";
import { useShallowEqualSelector } from "../../hooks";
import { user_spinner, user_errors } from "../../selectors/userSelectors";
import {
  notificationError,
  notificationSuccess,
  slugify,
} from "../util/helpers";

function Register() {
  const initRegisterUser = {
    company_email: "",
    no_of_users: "",
    company_name: "",
    site_url: "",
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const spinner = useShallowEqualSelector(user_spinner);

  const back_errors = useShallowEqualSelector(user_errors);

  const callbackFn = () => {
    registerClient(dispatch, values).then((res) => {
      if (res.status === "success") {
        notificationSuccess(res.message);
        navigate(`/modules?bId=${res.payload.business_id}`);
      } else if (res.status === "error") {
        notificationError(res.message);
      }
    });
  };

  const { values, errors, handleChange, handleSubmit } = useForm(
    callbackFn,
    initRegisterUser,
    registerValidator
  );

  function handleChangefirst(e) {
    values.site_url = slugify(e.target.value);
    handleChange(e);
  }

  return (
    <div className="container-scroller">
      <div className="row">
        <Sidebar />
        <div className="col-12 col-lg-7 p-0 bg-light">
          <div className="container-fluid page-body-wrapper full-page-wrapper px-0">
            <div className="content-wrapper d-flex align-items-center auth px-0 custom-background w-100">
              <div className="row w-100 mx-0">
                <div
                  className="auth__scroll col-12 col-lg-7 mx-auto content d-flex flex-column align-items-center h-100 p-4"
                  style={{ maxWidth: 500 }}
                >
                  <Logo light />
                  <div className="auth-form-light text-left py-4 py-lg-5 mt-4 px-3 px-lg-5 mx-lg-3 w-100 h-100 form-border">
                    <div>
                      <h4 className="text-center">Setup Your Account</h4>
                      <form className="pt-3" onSubmit={handleSubmit}>
                        <div className="form-group mb-3">
                          <div className="d-flex align-items-center mb-2 w-100">
                            <label
                              htmlFor="company_name"
                              className="d-block w-100 mb-0"
                            >
                              Company Name
                            </label>
                            <div
                              className={`${classnames(
                                "invalid-feedback",
                                "custom-feedback",
                                {
                                  "custom-visible":
                                    errors.company_name ||
                                    back_errors.company_name,
                                }
                              )} text-right h-100 mt-0`}
                            >
                              {errors.company_name ||
                                (back_errors.company_name &&
                                  back_errors.company_name[0])}
                            </div>
                          </div>
                          <Input
                            allowClear
                            type="text"
                            placeholder="Company Name"
                            onChange={handleChangefirst}
                            name="company_name"
                            size="large"
                            value={values.company_name}
                          />
                        </div>
                        <div className="form-group  mb-3">
                          <div className="d-flex align-items-center mb-2 w-100">
                            <label
                              htmlFor="company_email"
                              className="d-block mb-0 w-100"
                            >
                              Company Email
                            </label>
                            <div
                              className={`${classnames(
                                "invalid-feedback",
                                "custom-feedback",
                                {
                                  "custom-visible":
                                    errors.company_email ||
                                    back_errors.company_email,
                                }
                              )} text-right h-100 mt-0`}
                            >
                              {errors.company_email ||
                                (back_errors.company_email &&
                                  back_errors.company_email[0])}
                            </div>
                          </div>
                          <Input
                            allowClear
                            type="text"
                            placeholder="Company Email"
                            onChange={handleChange}
                            name="company_email"
                            size="large"
                            value={values.company_email}
                          />
                        </div>

                        <div className="form-group mb-3">
                          <div className="d-flex align-items-center w-100 mb-2">
                            <label
                              htmlFor="site_url"
                              className="d-block w-100 mb-0"
                            >
                              Site Address
                            </label>
                            <div
                              className={`${classnames(
                                "invalid-feedback",
                                "custom-feedback",
                                {
                                  "custom-visible":
                                    errors.site_url || back_errors.site_url,
                                }
                              )} text-right h-100 mt-0`}
                            >
                              {errors.site_url ||
                                (back_errors.site_url &&
                                  back_errors.site_url[0])}
                            </div>
                          </div>
                          <Input
                            allowClear
                            type="text"
                            placeholder="Site address"
                            onChange={handleChange}
                            name="site_url"
                            size="large"
                            value={values.site_url}
                            addonAfter={
                              <p className="m-0 text-muted h6">
                                .apps.sslinsight.com
                              </p>
                            }
                          />
                        </div>
                        <div className="form-group mb-3">
                          <div className="d-flex align-items-center w-100 mb-2">
                            <label
                              htmlFor="no_of_users"
                              className="d-block w-100 mb-0"
                            >
                              Number of Users
                            </label>
                            <div
                              className={`${classnames(
                                "invalid-feedback",
                                "custom-feedback",
                                {
                                  "custom-visible":
                                    errors.no_of_users ||
                                    back_errors.no_of_users,
                                }
                              )} text-right h-100 mt-0`}
                            >
                              {errors.no_of_users ||
                                (back_errors.no_of_users &&
                                  back_errors.no_of_users[0])}
                            </div>
                          </div>
                          <Input
                            allowClear
                            type="number"
                            placeholder="Company Number of Users"
                            onChange={handleChange}
                            name="no_of_users"
                            size="large"
                            value={values.no_of_users}
                          />
                        </div>
                        <div className="mt-4">
                          <Button
                            type="primary"
                            htmlType="submit"
                            block
                            style={{
                              // backgroundColor: "rgb(117, 11, 11)",
                              border: 0,
                              borderRadius: "8px",
                              cursor: "pointer",
                            }}
                            className="mybtn"
                            loading={spinner}
                            size="large"
                          >
                            Proceed
                          </Button>
                        </div>
                        {/* <div className="text-center mt-4 font-weight-light">
                          Already have an account?{" "}
                          <Link to="/login" className="text-primary">
                            Login here
                          </Link>
                        </div> */}
                      </form>
                    </div>
                  </div>
                  <div className="mt-4 text-light">
                    <small style={{ fontSize: 14 }}>
                      Privacy | Terms & Conditions
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Register;
